exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-en-gb-blog-category-tsx": () => import("./../../../src/pages/en-gb/blog/category.tsx" /* webpackChunkName: "component---src-pages-en-gb-blog-category-tsx" */),
  "component---src-pages-en-gb-blog-index-tsx": () => import("./../../../src/pages/en-gb/blog/index.tsx" /* webpackChunkName: "component---src-pages-en-gb-blog-index-tsx" */),
  "component---src-pages-en-gb-blog-tag-tsx": () => import("./../../../src/pages/en-gb/blog/tag.tsx" /* webpackChunkName: "component---src-pages-en-gb-blog-tag-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-author-tsx": () => import("./../../../src/templates/author.tsx" /* webpackChunkName: "component---src-templates-author-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-category-tsx": () => import("./../../../src/templates/category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-tag-tsx": () => import("./../../../src/templates/tag.tsx" /* webpackChunkName: "component---src-templates-tag-tsx" */)
}

